import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import moment from "moment"

import {
	ContentLayout,
	DatedLine,
	OptionBox,
	PootsyMultipleValuesBox,
	PootsyButton,
	PootsyHeading,
} from "./FunctionalDesign"
import {
	PootsyTextArea,
	PootsySelectInput,
	PootsyTextInput,
	PootsyCheckboxInput,
} from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"
import InputsChangeHandler from "./InputsChangeHandler"
import { AbsenceModal } from "./AbsenceModal"
import DeleteAbsenceWarningModal from "./DeleteAbsenceWarningModal"
import ImpactedBookingsModal from "./ImpactedBookingsModal"
import Paginator from "./Paginator"

import arrowRightIcon from "../static/images/arrow-right-icon.png"
import greenDotIcon from "../static/images/greendot-icon.png"
import redDotIcon from "../static/images/reddot-icon.png"

class WorkerAbsences extends Component {
	state = {
		refusalReason: "",
		currentRefusedAbsence: -1,
		activeCommentBoxIndex: -1,
		currentEditedAbsence: -1,
		activityCode: "",
		activityCategory: "",
		absenceTitle: "",
		absenceStatus: "",
		absenceNote: "",
		formC32ANumber: "",
		mustCallOnss: true,
		absenceStart: moment().set({ hours: 0, minutes: 0, seconds: 0 }),
		absenceEnd: moment().set({ hours: 23, minutes: 55, seconds: 0 }),
		absenceType: "",
		showRequired: {},
		absenceToApproveID: "",
		currentPage: 1,
	}
	componentDidMount = () => {
		this.fetchAbsences()
		this.setState = this.setState.bind(this)
	}
	UNSAFE_componentWillReceiveProps = newProps => {
		if (newProps.submitUpdateAbsenceSuccess) {
			this.props.dispatch(actions.acknowledgeRequestSuccess(actions.UPDATEABSENCE_SUCCESS))
			this.hideEditAbsenceModal()
		}
	}
	fetchAbsences = () => {
		let { id } = this.props.match.params
		let { currentPage } = this.state
		this.props.dispatch(
			actions.simpleFetch(
				"/front/affiliates/affiliate_workers/" +
					id +
					"/unavailabilities?page=" +
					currentPage,
				{ method: "GET" },
				"FETCHWORKERABSENCES"
			)
		)
	}
	updateQuery = async changes => {
		await this.setState({ currentPage: changes.value })
		this.fetchAbsences()
	}
	showEditAbsenceModal = e => {
		let { index } = e.target.dataset
		let { dispatch, absences } = this.props
		let currAbsence = absences[index].attributes
		this.setState({
			currentEditedAbsence: index,
			absenceNote: currAbsence.affiliate_note,
			absenceTitle: currAbsence.title,
			activityCode: currAbsence.activity_code,
			absenceType: currAbsence.motif_type,
			absenceStatus: currAbsence.status,
			activityCategory: currAbsence.activity_category,
			absenceStart: moment(currAbsence.start_time),
			absenceEnd: moment(currAbsence.end_time),
		})
		dispatch(actions.workerAbsencesStateChange({ showEditAbsenceModal: true }))
	}
	hideEditAbsenceModal = () => {
		this.props.dispatch(actions.workerAbsencesStateChange({ showEditAbsenceModal: false }))
	}
	handleAbsenceModalChange = changes => {
		this.setState(changes)
	}
	toggleAbsenceComments = e => {
		let { index } = e.target.dataset
		let { absences } = this.props
		index = parseInt(index, 10)
		if (!absences[index].attributes.note && !absences[index].attributes.refusal_reason) {
			return
		}
		let newValue = this.state.activeCommentBoxIndex === index ? -1 : index
		this.setState({ activeCommentBoxIndex: newValue })
	}
	toggleRefusalReasonModal = e => {
		let {
			dispatch,
			workerAbsences: { showRefusalReasonModal },
		} = this.props
		dispatch(
			actions.workerAbsencesStateChange({ showRefusalReasonModal: !showRefusalReasonModal })
		)
		if (e.target.dataset && e.target.dataset.index) {
			this.setState({ currentRefusedAbsence: e.target.dataset.index })
		}
	}
	openActivityCodeSelectionModal = e => {
		let { index } = e.target.dataset
		let { absences } = this.props
		this.setState({ absenceToApproveID: absences[index].id })
		this.toggleActivityCodeSelectionModal()
	}
	toggleActivityCodeSelectionModal = () => {
		let {
			dispatch,
			workerAbsences: { showActivityCodeSelectionModal },
		} = this.props
		dispatch(
			actions.workerAbsencesStateChange({
				showActivityCodeSelectionModal: !showActivityCodeSelectionModal,
			})
		)
		this.setState({ activityCode: "", activityCategory: "" })
	}
	handleRefusalReasonChange = e => {
		e.stopPropagation()
		let { value } = e.target
		this.setState({ refusalReason: value })
	}
	closeCancelWarningModal = () => {
		this.props.dispatch(
			actions.workerAbsencesStateChange({
				showCancelWarning: false,
				absencePendingCancel: -1,
			})
		)
	}
	reviewAbsence = e => {
		let { index, choice } = e.target.dataset
		let { refusalReason } = this.state
		let { dispatch, currentWorker, absences } = this.props
		let currAbsence = absences[index]
		let url =
			"/front/affiliates/affiliate_workers/" +
			currentWorker.id +
			"/unavailabilities/" +
			currAbsence.id +
			"/" +
			choice
		let init = { method: "POST", body: JSON.stringify({ reason: refusalReason }) }
		dispatch(actions.simpleFetch(url, init, choice.toUpperCase() + "ABSENCE"))
	}
	submitUpdatedAbsence = async () => {
		let {
			dispatch,
			currentWorker,
			absences,
			impactedBookings,
			impactedBookingsModal: { forceUnlink },
		} = this.props
		let {
			currentEditedAbsence,
			absenceTitle,
			activityCode,
			activityCategory,
			absenceStatus,
			absenceType,
			absenceStart,
			absenceEnd,
			absenceNote,
			formC32ANumber,
			mustCallOnss,
		} = this.state
		let currAbsence = absences[currentEditedAbsence]
		let init = {
			method: "PUT",
			body: JSON.stringify({
				title: absenceTitle,
				activity_code: activityCode,
				activity_category: activityCategory,
				status: absenceStatus,
				motif_type: absenceType,
				start_time: absenceStart.format(),
				end_time: absenceEnd.format(),
				affiliate_note: absenceNote,
				form_c32a_number: formC32ANumber,
				must_call_onss: mustCallOnss,
				worker_contract_id: currAbsence.attributes.worker_contract_id,
				force_unlink: forceUnlink,
				impacted_bookings_actions: actions.denormalizeImpactedBookingActions(
					impactedBookings
				),
			}),
		}
		let url =
			"/front/affiliates/affiliate_workers/" +
			currentWorker.id +
			"/unavailabilities/" +
			currAbsence.id

		dispatch(actions.simpleFetch(url, init, "UPDATEABSENCE"))
		this.hideEditAbsenceModal()
	}
	cancelAbsence = e => {
		let { index, kbc } = e.target.dataset
		let { dispatch, workerAbsences, currentWorker, absences } = this.props
		let currAbsence = absences[workerAbsences.absencePendingCancel]
		let body = {}
		if (index) {
			currAbsence = absences[index]
		}
		if (kbc !== undefined) {
			body.keep_bookings_cancelled = kbc
		}
		let url =
			"/front/affiliates/affiliate_workers/" +
			currentWorker.id +
			"/unavailabilities/" +
			currAbsence.id
		let init = { method: "DELETE", body: JSON.stringify(body) }
		dispatch(actions.simpleFetch(url, init, "CANCELABSENCE", { absenceIndex: index }))
		dispatch(
			actions.workerAbsencesStateChange({
				showCancelWarning: false,
				absencePendingCancel: -1,
			})
		)
	}
	approveAbsence = () => {
		let {
			absenceToApproveID,
			activityCode,
			activityCategory,
			formC32ANumber,
			mustCallOnss,
		} = this.state
		this.props.dispatch(
			actions.approveAbsence({
				absenceToApproveID,
				activityCode,
				activityCategory,
				formC32ANumber,
				mustCallOnss,
			})
		)
	}
	renderButtons = (entry, index) => {
		let { t } = this.context
		if (entry.attributes.status === "pending") {
			return (
				<Fragment>
					<PootsyButton
						key="edit"
						text={t("edit")}
						theme="cancel"
						onClick={this.showEditAbsenceModal}
						dataset={{ "data-index": index }}
					/>
					<PootsyButton
						onClick={this.openActivityCodeSelectionModal}
						customClass="absence-item-approve-button"
						text={t("✓")}
						theme="fadedGreen"
						dataset={{ "data-index": index }}
					/>
					<PootsyButton
						key="refuse"
						onClick={this.toggleRefusalReasonModal}
						customClass="absence-item-refuse-button"
						text={t("✕")}
						theme="fadedRed"
						dataset={{ "data-index": index }}
					/>
				</Fragment>
			)
		} else if (entry.attributes.status === "approved") {
			return (
				<PootsyButton
					text={t("cancel_absence")}
					theme="cancel"
					onClick={this.cancelAbsence}
					dataset={{ "data-index": index }}
				/>
			)
		}
	}
	render() {
		let { t } = this.context
		let {
			currentWorker,
			absences,
			workerAbsences,
			currAffiliate,
			impactedBookingsModal: { showImpactedBookingsModal, impactedOas },
		} = this.props
		let { coveredAreas, email, mobilePhone, sodexoNumber, languages, rating } = currentWorker
		let {
			activeCommentBoxIndex,
			absenceTitle,
			activityCode,
			activityCategory,
			absenceStatus,
			absenceStart,
			absenceEnd,
			absenceNote,
			formC32ANumber,
			mustCallOnss,
			refusalReason,
			currentRefusedAbsence,
			showRequired,
			currentPage,
		} = this.state
		let optionsBoxes = (
			<OptionBox>
				<PootsyMultipleValuesBox
					values={[
						{ label: t("average_rating"), values: [rating] },
						{ label: t("phone"), values: [mobilePhone] },
						{ label: t("email"), values: [email] },
						{
							label: t("languages"),
							values: languages ? languages.map(entry => entry.name) : [],
						},
						{ label: t("sodexo_number"), values: [sodexoNumber] },
						{ label: t("areas_covered"), values: coveredAreas || [] },
					]}
				/>
			</OptionBox>
		)
		let filteredCodes = currAffiliate.secSocEvents.codes
		if (activityCategory) {
			filteredCodes = filteredCodes.filter(entry => entry.category_id === activityCategory)
		}
		return (
			<ContentLayout optionsBoxes={optionsBoxes}>
				{workerAbsences.showEditAbsenceModal && (
					<AbsenceModal
						absenceTitle={absenceTitle}
						activityCode={activityCode}
						activityCategory={activityCategory}
						absenceStatus={absenceStatus}
						absenceStart={absenceStart}
						absenceEnd={absenceEnd}
						absenceNote={absenceNote || ""}
						formC32ANumber={formC32ANumber || ""}
						mustCallOnss={mustCallOnss || true}
						lastEuAbsenceDate={currentWorker.lastEuAbsenceDate}
						toggleModal={this.hideEditAbsenceModal}
						editMode={true}
						onChange={this.handleAbsenceModalChange}
						submit={this.submitUpdatedAbsence}
						t={t}
					/>
				)}
				{workerAbsences.showCancelWarning && (
					<DeleteAbsenceWarningModal
						toggleModal={this.closeCancelWarningModal}
						impactedOAs={impactedOas}
						deleteAbsence={this.cancelAbsence}
					/>
				)}
				{workerAbsences.showRefusalReasonModal && (
					<ModalLayout
						title={t("absence_refusal.reason_modal_title")}
						formDivClass="cleaner-absences-refusal-reason-modal"
						closeModal={this.closeCancelWarningModal}
					>
						<div className="refusal-reason-prompt">{t("refusal_reason_prompt")}</div>
						<PootsyTextArea
							label={t("refusal_reason")}
							value={refusalReason}
							onChange={this.handleRefusalReasonChange}
						/>
						<div className="buttons">
							<PootsyButton
								text={t("submit")}
								dataset={{
									"data-index": currentRefusedAbsence,
									"data-choice": "refuse",
								}}
								onClick={this.reviewAbsence}
							/>
							<PootsyButton
								text={t("cancel")}
								theme="cancel"
								onClick={this.toggleRefusalReasonModal}
							/>
						</div>
					</ModalLayout>
				)}
				{workerAbsences.showActivityCodeSelectionModal && (
					<InputsChangeHandler onChange={this.setState}>
						<ModalLayout
							title={t("absence_approval.activity_code_selection_modal_title")}
							formDivClass="worker-absences-show-activity-code-selection"
							closeModal={this.toggleActivityCodeSelectionModal}
						>
							<PootsySelectInput
								name="activityCategory"
								defaultText={t("activity_category")}
								selected={activityCategory}
								showRequired={showRequired.activityCategory}
								options={currAffiliate.secSocEvents.categories.map(entry => ({
									label: t(entry.label),
									value: entry.id,
								}))}
							/>
							<PootsySelectInput
								name="activityCode"
								defaultText={t("activity_type")}
								selected={activityCode}
								showRequired={showRequired.activityCode}
								options={filteredCodes.map(entry => ({
									label: t(entry.label),
									value: entry.id,
								}))}
							/>
							{activityCode === currAffiliate.euSSCode && (
								<Fragment>
									<PootsyTextInput
										name="formC32ANumber"
										label={t("form_c32a_number")}
										value={formC32ANumber}
									/>
									<PootsyCheckboxInput
										name="mustCallOnss"
										label={t("economic_unemployment_must_be_done_on_onss")}
										checked={mustCallOnss}
									/>
								</Fragment>
							)}
							<div className="buttons">
								<PootsyButton text={t("submit")} onClick={this.approveAbsence} />
								<PootsyButton
									text={t("cancel")}
									theme="cancel"
									onClick={this.toggleActivityCodeSelectionModal}
								/>
							</div>
						</ModalLayout>
					</InputsChangeHandler>
				)}
				{showImpactedBookingsModal && (
					<ImpactedBookingsModal
						onSubmit={this.submitUpdatedAbsence}
						triggerActionExpected={actions.UPDATEABSENCE_FAILURE}
					/>
				)}
				{showImpactedBookingsModal && (
					<ImpactedBookingsModal
						onSubmit={this.approveAbsence}
						triggerActionExpected={actions.APPROVEABSENCE_FAILURE}
					/>
				)}
				<section className="cleaner-absences-main">
					<div className="cleaner-absences-list">
						{absences.map((entry, index) => {
							let currentCode = filteredCodes.find(
								code => code.id === entry.attributes.activity_code
							)
							return (
								<DatedLine
									key={entry.id}
									date={entry.attributes.date_day}
									month={entry.attributes.date_month}
									customClass="cleaner-absence-item"
								>
									<div className="absence-item-main">
										{t(entry.attributes.type_of_local)}
									</div>
									<div className="absence-item-start-end">
										<div className="start-time">
											<label>{t("start")}:</label>
											<span>
												{moment(entry.attributes.start_time).format(
													"DD/MM/YY HH:mm"
												)}
											</span>
										</div>
										<img alt="presentation" src={arrowRightIcon} />
										<div className="end-time">
											<label>{t("end")}:</label>
											<span>
												{moment(entry.attributes.end_time).format(
													"DD/MM/YY HH:mm"
												)}
											</span>
										</div>
									</div>
									<div className="absence-item-status">
										<label className="absence-item-status-label">
											{t(entry.attributes.status)}
										</label>
										<img
											alt="presentation"
											className="absence-item-status-icon"
											src={
												entry.attributes.status === "approved"
													? greenDotIcon
													: redDotIcon
											}
										/>
									</div>
									<div className="absence-affiliate-note">
										<label>{t("note")}:</label>
										<span>{entry.attributes.affiliate_note}</span>
									</div>
									{currentCode && (
										<div className="absence-ss-code">
											<label>{t("activity_code")}:</label>
											<span>{`${currentCode.id} - ${t(
												currentCode.label
											)}`}</span>
											<label>{t(entry.attributes.motif_type)}</label>
										</div>
									)}
									<div className="absence-item-buttons">
										<div
											className={
												"absence-item-comments-box-wrapper " +
												(activeCommentBoxIndex === index)
											}
										>
											<PootsyHeading text={t("comments")} fontSize="1.2em" />
											<div className="absence-item-comments-box">
												{entry.attributes.note && (
													<div className="absence-item-comment">
														<span className="initials-circle">
															{currentWorker.initials}
														</span>
														{entry.attributes.note}
													</div>
												)}
												{entry.attributes.refusal_reason && (
													<div className="absence-item-comment refusal-reason">
														<div className="label">
															{t("refusal_reason")}
														</div>
														<div className="message">
															{entry.attributes.refusal_reason}
														</div>
													</div>
												)}
											</div>
										</div>
										{(entry.attributes.note ||
											entry.attributes.refusal_reason) && (
											<PootsyButton
												text={
													activeCommentBoxIndex === index
														? t("hide_note")
														: t("show_note")
												}
												theme="cancel"
												customClass="cleaner-absence-edit-button"
												dataset={{ "data-index": index }}
												onClick={this.toggleAbsenceComments}
											/>
										)}
										{this.renderButtons(entry, index)}
									</div>
								</DatedLine>
							)
						})}
					</div>
				</section>
				<Paginator
					name="pageQuery"
					currPage={currentPage}
					totalPages={workerAbsences.pagination.total_pages}
					goToPage={this.updateQuery}
				/>
			</ContentLayout>
		)
	}
}

WorkerAbsences.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentWorker: state.redData.currentWorker,
	absences: state.redData.currentWorkerAbsences,
	workerAbsences: state.redComponents.workerAbsences,
	impactedBookings: state.redData.impactedBookings,
	submitUpdateAbsenceSuccess: state.requestsSuccess[actions.UPDATEABSENCE_SUCCESS],
	currAffiliate: state.redData.currentAffiliate,
	impactedBookingsModal: state.redComponents.impactedBookingsModal,
})
export default connect(mapStateToProps)(WorkerAbsences)
