import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { history } from "../history"

import { LabeledDateTime, PootsyButton } from "./FunctionalDesign"
import { PootsySelectInput, PootsyTextInput } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"
import InputsChangeHandler from "./InputsChangeHandler"

export default class TimesheetDay extends Component {
	state = {
		showGroupSCEModal: false,
		pendingModification: {},
	}
	toggleGroupSCEModal = () => {
		this.setState(state => ({ showGroupSCEModal: !state.showGroupSCEModal }))
	}
	handleChanges = changes => {
		let { timesheetID, workerId, day, handleScheduleModif } = this.props
		let key = Object.keys(changes)[0]
		let value = changes[key]
		let splits = key.split(":")
		let eventIndex = parseInt(splits[0], 10)
		let property = splits[1]
		let modification = [timesheetID, workerId, day, eventIndex, { [property]: value }]
		if (value === "CE") {
			this.toggleGroupSCEModal()
			this.setState({ pendingModification: modification })
			return
		}
		handleScheduleModif(...modification)
	}
	submitCEModification = () => {
		let { events } = this.props
		let { timesheetID, workerId, day, handleScheduleModif } = this.props
		let { pendingModification } = this.state
		let modifiedEvent = events[pendingModification[3]]
		events.forEach((entry, index) => {
			if (
				entry.event_type === modifiedEvent.event_type &&
				entry.event_id === modifiedEvent.event_id
			) {
				return
			}
			handleScheduleModif(timesheetID, workerId, day, index, { affiliate_deleted: true })
		})
		handleScheduleModif(...pendingModification)
		this.setState({ pendingModification: {}, showGroupSCEModal: false })
	}
	renderDayDisplayMode = (day, events) => {
		let { t } = this.context
		let { secSocEvents, intermediateClosureDates } = this.props
		day = moment(day)
		events = events.filter(e => !e.affiliate_deleted)
		let dayInPast = day.isBefore(moment(), "day")
		let eventsToDisplay = events.map(event => {
			let event2 = JSON.parse(JSON.stringify(event))
			event2.start_time = moment(event2.start_time)
			event2.end_time = moment(event2.end_time)
			event2.duration = moment.duration(event2.end_time.diff(event2.start_time))
			event2.displayDuration = `${event2.duration.hours()}:${
				event2.duration.minutes() < 10
					? "0" + event2.duration.minutes()
					: event2.duration.minutes()
			}`
			return event2
		})

		let dayIsClosed = intermediateClosureDates.some(entry => entry.isSameOrAfter(day))
		return (
			<div
				key={day.format()}
				className={
					"secsoc-schedule-day" +
					(dayInPast ? " day-in-past" : "") +
					(dayIsClosed ? " day-is-closed" : "")
				}
			>
				{eventsToDisplay.length === 0 && (
					<div className="secsoc-schedule-event-line no-event">
						<div className="column date">{day.format("ddd DD/MM/YYYY")}</div>
						<div className="padder" />
					</div>
				)}
				{eventsToDisplay.map((e, i) => {
					let data = {}
					data.customer = ""
					data.address = ""
					data.km = ""
					data.markUnmapped = true
					data.plannedHours = e.displayDuration

					data.km = e.distance_in_km
					if (e.social_secretary_code) {
						data.motif_type = e.motif_type
						data.markUnmapped = false
						let secSocTermID = e.social_secretary_code
						let secSocTerm = secSocEvents.codes.find(term => term.id === secSocTermID)
						data.eventType = secSocTerm
							? secSocTerm.id + " - " + t(secSocTerm.label)
							: t(e.event_type)
					} else {
						data.eventType = t(e.event_type)
						data.markUnmapped = true
						if (e.event_type === "other_activity") {
							data.markUnmapped = false
							let secSocTerm = secSocEvents.codes.find(
								term => term.id === e.activity_code
							)
							if (secSocTerm) {
								data.eventType = `${secSocTerm.id} - ${t(secSocTerm.label)}`
							}
						}
					}
					if (["booking", "extra_hour", "comp_hour"].includes(e.event_type)) {
						if (e.customer) {
							data.onClickAddress = function() {
								history.push({ pathname: "/bookings/" + e.event_id })
							}
							let voucherType = e.voucher_type === "paper" ? "P" : "E"
							// voucher_count is deprecated, when all new events will be using 'billable_hours'
							// the next line will be removed
							let billableHours = e.billable_hours || e.voucher_count
							if (!e.social_secretary_code && e.service_delivery_status) {
								data.eventType = t(e.service_delivery_status)
								if (e.service_delivery_status === "assigned") {
									data.markUnmapped = false
								}
							}
							data.realHours = billableHours ? billableHours + ":00" : ""
							data.vouchersExpected = e.expected_voucher_count
								? e.expected_voucher_count + " " + voucherType
								: ""
							data.vouchersReceived = ""
							if (e.voucher_received) {
								data.vouchersReceived = `${e.voucher_received} ${voucherType}`
							}
							data.customer = e.customer.display_name
							data.address = e.customer.address

							data.km = parseFloat(data.km)
								.toFixed(2)
								.toString()
								.replace(".", ",")
							if (data.km === "NaN") {
								data.km = ""
							}
						} else {
							data.markUnmapped = false
						}
					}

					let kilometers = data.km ? data.km.toString().replace(".", ",") : ""

					return (
						<div key={i} className="secsoc-schedule-event-line">
							<div className="column date">
								{i === 0 && day.format("ddd DD/MM/YYYY")}
							</div>
							<div className={"column type" + (data.markUnmapped ? " unmapped" : "")}>
								{data.eventType}
								{data.motif_type ? ` - ${t(data.motif_type)}` : ""}
							</div>
							<div className="column start">{e.start_time.format("HH:mm")}</div>
							<div className="column end">{e.end_time.format("HH:mm")}</div>
							<div className="column hours">
								<div className="planned">{data.plannedHours}</div>
								<div className="real">{data.realHours}</div>
							</div>
							<div className="column vouchers">
								<div className="expected">{data.vouchersExpected}</div>
								<div className="received">{data.vouchersReceived}</div>
							</div>
							<div className="column customer">
								<div className="name">{data.customer}</div>
								<div className="address" onClick={data.onClickAddress}>
									{data.address}
								</div>
							</div>
							<div className="column KM">{kilometers}</div>
						</div>
					)
				})}
			</div>
		)
	}
	renderDayEditMode = (day, events) => {
		let { t } = this.context
		let { toggleNewEventModal, secSocEvents, softDeleteEvent } = this.props
		day = moment(day)
		const allowedCategories = ["timesheet", "overpay"]
		let eventTypeOptions = secSocEvents.codes
			.filter(entry => allowedCategories.includes(entry.timesheet_category))
			.map(term => ({ label: term.id + " " + t(term.label), value: term.id }))
		return (
			<div key={day.format()} className="secsoc-schedule-day edit-mode">
				{events.length === 0 && (
					<div className="secsoc-schedule-event-line no-event">
						<div className="column date">{day.format("ddd DD/MM/YYYY")}</div>
						<div className="padder" />
					</div>
				)}
				{events.map((e, i) => {
					let data = {}
					data.customer = ""
					data.address = ""
					data.km = ""
					if (["booking", "extra_hour"].includes(e.event_type)) {
						let voucherType = e.voucher_type === "paper" ? "P" : "E"
						// voucher_count is deprecated, when all new events will be using 'billable_hours'
						// the next line will be removed
						let billableHours = e.billable_hours || e.voucher_count
						let voucherReceived = e.voucher_received || "0"
						data.plannedHours = e.displayDuration
						data.realHours = billableHours
						data.vouchersExpected = e.expected_voucher_count + " " + voucherType
						data.vouchersReceived = voucherType === "P" ? voucherReceived + " P" : ""
						data.customer = e.customer.display_name
						data.address = e.customer.address
					}
					if (e.affiliate_deleted) {
						return (
							<div key={i} className="secsoc-schedule-event-line">
								<div className="column date">
									{i === 0 && day.format("ddd DD/MM/YYYY")}
								</div>
							</div>
						)
					}

					let kilometers = e.distance_in_km
						? e.distance_in_km.toString().replace(".", ",")
						: ""

					return (
						<div key={i} className="secsoc-schedule-event-line">
							<div className="column date">
								<div className="date">
									{i === 0 && day.format("ddd DD/MM/YYYY")}
								</div>
							</div>
							<div className="column type">
								<PootsySelectInput
									name={i + ":social_secretary_code"}
									defaultText={t("event_type")}
									allowEmpty={true}
									selected={e.social_secretary_code || e.activity_code}
									options={eventTypeOptions}
								/>
							</div>
							<div className="column start">
								<LabeledDateTime
									label={t("start_time")}
									showDatePicker={false}
									showLabel={false}
									timePickerProps={{
										name: i + ":start_time",
										value: e.start_time,
										onChange: this.handleChanges,
									}}
								/>
							</div>
							<div className="column end">
								<LabeledDateTime
									label={t("end_time")}
									showDatePicker={false}
									showLabel={false}
									timePickerProps={{
										name: i + ":end_time",
										value: e.end_time,
										onChange: this.handleChanges,
									}}
								/>
							</div>
							<div className="column hours">
								<div className="planned">{data.plannedHours}</div>
								<div className="real">
									<PootsyTextInput
										type="number"
										label="real"
										name={i + ":billable_hours"}
										value={data.realHours}
									/>
								</div>
							</div>
							<div className="column vouchers">
								<div className="expected">{data.vouchersExpected}</div>
								<div className="received">{data.vouchersReceived}</div>
							</div>
							<div className="column customer">
								<div className="name">{data.customer}</div>
								<div className="address">{data.address}</div>
							</div>
							<div className="column km">
								<PootsyTextInput
									name={i + ":distance_in_km"}
									label="KM"
									type="number"
									value={kilometers}
								/>
							</div>
							<div className="column soft-delete">
								<div
									className="soft-delete-button"
									data-day={day.format()}
									data-eventindex={i}
									onClick={softDeleteEvent}
								>
									x
								</div>
							</div>
						</div>
					)
				})}
				<div
					className="add-event-button"
					data-day={day.format()}
					onClick={toggleNewEventModal}
				>
					{" "}
					+{" "}
				</div>
			</div>
		)
	}
	render() {
		let { t } = this.context
		let { showGroupSCEModal } = this.state
		let { editMode, day, events } = this.props
		let groupSCEModalButtons = [
			<PootsyButton key="a" text={t("yes")} onClick={this.submitCEModification} />,
			<PootsyButton key="b" text={t("no")} onClick={this.toggleGroupSCEModal} />,
		]
		return editMode ? (
			<InputsChangeHandler onChange={this.handleChanges}>
				{showGroupSCEModal && (
					<ModalLayout
						formDivClass="group-s-CE-modal"
						closeModal={this.toggleGroupSCEModal}
						buttons={groupSCEModalButtons}
					>
						{t("timesheet_modification_group_s_CE_selected_explanation")}
					</ModalLayout>
				)}
				<div className="secsoc-schedule-day-wrapper">
					{this.renderDayEditMode(day, events)}
				</div>
			</InputsChangeHandler>
		) : (
			<div className="secsoc-schedule-day-wrapper">
				{this.renderDayDisplayMode(day, events)}
			</div>
		)
	}
}

TimesheetDay.contextTypes = { t: PropTypes.func }
